import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import '@reach/dialog/styles.css';

import Container from '../components/Container';
import Text from '../components/Text';
import config from '../config';

const Content = styled.div`
  height: 80%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const RootContainer = styled.div`
  height: calc(100vh - ${config.sizes.header + config.sizes.footer}px);
  width: 100%;

  > div {
    width: 100%;
    height: 100%;
  }
`;

const News = () => {
  return (
    <>
      <Helmet>
        <title>Excel Fitness Club | Events</title>
      </Helmet>
      <RootContainer>
        <Container>
          <Text as="h1" size="x-large" weight="bold">
            News
          </Text>
          <Content>
            <Text as="h2" size="large" weight="bold">
              Check back soon!
            </Text>
          </Content>
        </Container>
      </RootContainer>
    </>
  );
};

export default News;
